import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

// Import WP blocks using Next Dynamic Imports
// @see https://nextjs.org/docs/advanced-features/dynamic-import

/**
 * Decide which block component to display.
 * @author Americaneagle.com
 * @param  {object}  block The block data.
 * @param  {number}  index A unique key required by React.
 * @return {Element}       A block-based component.
 */
export default function displayBlock(block, index) {
  const {attributes, name, innerBlocks} = block

  // prettier-ignore
  switch (name) {
    /* -- CORE BLOCKS -- */
    case 'core/block': {
      const BlockReusable = dynamic(
        () => import('@/components/blocks/core/BlockReusable')
      )
      return <BlockReusable innerBlocks={innerBlocks} key={index} />
    }

    case 'core/button': {
      const BlockButton = dynamic(
        () => import('@/components/blocks/core/BlockButton')
      )
      return <BlockButton {...attributes} key={index} />
    }

    case 'core/buttons': {
      const BlockButtons = dynamic(
        () => import('@/components/blocks/core/BlockButtons')
      )
      return (
        <BlockButtons {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'core/columns': {
      const BlockColumns = dynamic(
        () => import('@/components/blocks/core/BlockColumns')
      )
      return (
        <BlockColumns {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'core/cover': {
      const BlockCover = dynamic(
        () => import('@/components/blocks/core/BlockCover')
      )
      return (
        <BlockCover {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'core/embed': {
      const BlockEmbed = dynamic(
        () => import('@/components/blocks/core/BlockEmbed')
      )
      return <BlockEmbed {...attributes} key={index} />
    }

    case 'core/freeform': {
      return null
    }

    case 'core/gallery': {
      const BlockImageGallery = dynamic(
        () => import('@/components/blocks/core/BlockImageGallery')
      )
      return (
        <BlockImageGallery
          {...attributes}
          innerBlocks={innerBlocks}
          key={index}
        />
      )
    }

    case 'core/group': {
      const BlockGroup = dynamic(
        () => import('@/components/blocks/core/BlockGroup')
      )
      return (
        <BlockGroup {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'core/heading': {
      const BlockHeadings = dynamic(
        () => import('@/components/blocks/core/BlockHeadings')
      )
      return <BlockHeadings {...attributes} key={index} />
    }

    case 'core/html': {
      const BlockHtml = dynamic(
        () => import('@/components/blocks/core/BlockHtml')
      )
      return <BlockHtml {...attributes} key={index} />
    }

    case 'core/image': {
      const BlockImage = dynamic(
        () => import('@/components/blocks/core/BlockImage')
      )
      return <BlockImage {...attributes} key={index} />
    }

    case 'core/list': {
      const BlockList = dynamic(
        () => import('@/components/blocks/core/BlockList')
      )
      return <BlockList {...attributes} innerBlocks={innerBlocks} key={index} />
    }

    case 'core/list-item': {
      const BlockListItem = dynamic(
        () => import('@/components/blocks/core/BlockListItem')
      )
      return (
        <BlockListItem {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'core/media-text': {
      const BlockMediaText = dynamic(
        () => import('@/components/blocks/core/BlockMediaText')
      )
      return (
        <BlockMediaText {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'core/paragraph': {
      const BlockParagraph = dynamic(
        () => import('@/components/blocks/core/BlockParagraph')
      )
      return <BlockParagraph {...attributes} key={index} />
    }

    case 'core/pullquote': {
      const BlockPullQuote = dynamic(
        () => import('@/components/blocks/core/BlockPullQuote')
      )
      return <BlockPullQuote {...attributes} key={index} />
    }

    case 'core/quote': {
      const BlockQuote = dynamic(
        () => import('@/components/blocks/core/BlockQuote')
      )
      return (
        <BlockQuote {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'core/separator': {
      const BlockSeparator = dynamic(
        () => import('@/components/blocks/core/BlockSeparator')
      )
      return <BlockSeparator {...attributes} key={index} />
    }

    case 'core/spacer': {
      const BlockSpacer = dynamic(
        () => import('@/components/blocks/core/BlockSpacer')
      )
      return <BlockSpacer {...attributes} key={index} />
    }

    case 'core/table': {
      const BlockTable = dynamic(
        () => import('@/components/blocks/core/BlockTable')
      )
      return <BlockTable {...attributes} key={index} />
    }

    /* -- GRAVITY FORMS BLOCKS -- */
    case 'gravityforms/form': {
      const BlockGravityForm = dynamic(
        () => import('@/components/blocks/core/BlockGravityForm')
      )
      return <BlockGravityForm {...attributes} key={index} />
    }

    /* -- CUSTOM BLOCKS -- */
    case 'mecum/accordion': {
      const BlockAccordion = dynamic(
        () => import('@/components/blocks/custom/BlockAccordion')
      )
      return (
        <BlockAccordion {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'mecum/alert': {
      const BlockAlert = dynamic(
        () => import('@/components/blocks/custom/BlockAlert')
      )
      return <BlockAlert {...attributes} key={index} />
    }

    case 'mecum/auction-catalog': {
      const BlockAuctionCatalog = dynamic(
        () => import('@/components/blocks/custom/BlockAuctionCatalog'),
        {ssr: false}
      )
      return <BlockAuctionCatalog {...attributes} key={index} />
    }

    case 'mecum/auction-countdown': {
      const BlockAuctionCountdown = dynamic(
        () => import('@/components/blocks/custom/BlockAuctionCountdown')
      )
      return <BlockAuctionCountdown {...attributes} key={index} />
    }

    case 'mecum/auction-count-up': {
      const BlockAuctionCountUp = dynamic(
        () => import('@/components/blocks/custom/BlockAuctionCountUp')
      )
      return <BlockAuctionCountUp {...attributes} key={index} />
    }

    case 'mecum/auction-selector': {
      const BlockAuctionSelector = dynamic(
        () => import('@/components/blocks/custom/BlockAuctionSelector'),
        {ssr: false}
      )
      return <BlockAuctionSelector {...attributes} key={index} />
    }

    case 'mecum/bidder-registration': {
      const BlockBidderRegistration = dynamic(
        () => import('@/components/blocks/custom/BlockBidderRegistration'),
        {ssr: false}
      )
      return <BlockBidderRegistration {...attributes} key={index} />
    }

    case 'mecum/call-to-action': {
      const BlockCallToAction = dynamic(
        () => import('@/components/blocks/custom/BlockCallToAction')
      )
      return (
        <BlockCallToAction
          {...attributes}
          innerBlocks={innerBlocks}
          key={index}
        />
      )
    }

    case 'mecum/carousel': {
      const BlockCarousel = dynamic(
        () => import('@/components/blocks/custom/BlockCarousel')
      )
      return (
        <BlockCarousel {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'mecum/consignment': {
      const BlockConsignment = dynamic(
        () => import('@/components/blocks/custom/BlockConsignment'),
        {ssr: false}
      )
      return <BlockConsignment {...attributes} key={index} />
    }

    case 'mecum/expand-show-more': {
      const BlockExpandShowMore = dynamic(
        () => import('@/components/blocks/custom/BlockExpandShowMore')
      )
      return (
        <BlockExpandShowMore
          {...attributes}
          innerBlocks={innerBlocks}
          key={index}
        />
      )
    }

    case 'mecum/lot-search-results': {
      const BlockLotSearchResults = dynamic(
        () => import('@/components/blocks/custom/BlockLotSearchResults'),
        {ssr: false}
      )
      return <BlockLotSearchResults {...attributes} key={index} />
    }

    case 'mecum/lot-selector': {
      const BlockLotSelector = dynamic(
        () => import('@/components/blocks/custom/BlockLotSelector'),
        {ssr: false}
      )
      return <BlockLotSelector {...attributes} key={index} />
    }

    case 'mecum/lot-selector-v2': {
      const BlockLotSelectorV2 = dynamic(
        () => import('@/components/blocks/custom/BlockLotSelectorV2'),
        {ssr: false}
      )
      return <BlockLotSelectorV2 {...attributes} key={index} />
    }

    case 'mecum/media': {
      const BlockMedia = dynamic(
        () => import('@/components/blocks/custom/BlockMedia'),
        {ssr: false}
      )
      return <BlockMedia {...attributes} key={index} />
    }

    case 'mecum/media-content-selector': {
      const BlockMediaContentSelector = dynamic(
        () => import('@/components/blocks/custom/BlockMediaContentSelector'),
        {ssr: false}
      )
      return <BlockMediaContentSelector {...attributes} key={index} />
    }

    case 'mecum/newsletter-cta': {
      const BlockNewsletterCTA = dynamic(
        () => import('@/components/blocks/custom/BlockNewsletterCTA')
      )
      return (
        <BlockNewsletterCTA
          {...attributes}
          innerBlocks={innerBlocks}
          key={index}
        />
      )
    }

    case 'mecum/placeholder': {
      return null
    }

    case 'mecum/recently-viewed-lots': {
      const BlockRecentlyViewedLots = dynamic(
        () => import('@/components/blocks/custom/BlockRecentlyViewedLots'),
        {ssr: false}
      )
      return <BlockRecentlyViewedLots {...attributes} key={index} />
    }

    case 'mecum/section': {
      const BlockSection = dynamic(
        () => import('@/components/blocks/custom/BlockSection')
      )
      return (
        <BlockSection {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'mecum/start-your-experience': {
      const BlockStartYourExperience = dynamic(
        () => import('@/components/blocks/custom/BlockStartYourExperience')
      )
      return <BlockStartYourExperience {...attributes} key={index} />
    }

    case 'mecum/stats': {
      const BlockStats = dynamic(
        () => import('@/components/blocks/custom/BlockStats')
      )
      return (
        <BlockStats {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'mecum/tabs': {
      const BlockTabs = dynamic(
        () => import('@/components/blocks/custom/BlockTabs')
      )
      return <BlockTabs {...attributes} innerBlocks={innerBlocks} key={index} />
    }

    case 'mecum/testimonial': {
      const BlockTestimonial = dynamic(
        () => import('@/components/blocks/custom/BlockTestimonial')
      )
      return (
        <BlockTestimonial
          {...attributes}
          innerBlocks={innerBlocks}
          key={index}
        />
      )
    }

    case 'mecum/tile': {
      const BlockTile = dynamic(
        () => import('@/components/blocks/custom/BlockTile')
      )
      return <BlockTile {...attributes} innerBlocks={innerBlocks} key={index} />
    }

    case 'mecum/top-n-lots': {
      const BlockTopNLots = dynamic(
        () => import('@/components/blocks/custom/BlockTopNLots'),
        {ssr: false}
      )
      return <BlockTopNLots {...attributes} key={index} />
    }

    case 'mecum/transport-map': {
      const BlockTransportMap = dynamic(
        () => import('@/components/blocks/custom/BlockTransportMap'),
        {ssr: false}
      )
      return (
        <BlockTransportMap
          {...attributes}
          innerBlocks={innerBlocks}
          key={index}
        />
      )
    }

    case 'mecum/ad-slot': {
      const BlockAdSlot = dynamic(
        () => import('@/components/blocks/custom/BlockAdSlot')
      )
      return (
        <BlockAdSlot {...attributes} innerBlocks={innerBlocks} key={index} />
      )
    }

    case 'vimeo/create': {
      const BlockVimeo = dynamic(
        () => import('@/components/blocks/custom/BlockVimeo')
      )

      return <BlockVimeo {...attributes} key={index} />
    }

    case 'mecum/youtube-nocookie': {
      const BlockYouTubeNoCookie = dynamic(
        () => import('@/components/blocks/core/BlockEmbed')
      )
      if (!attributes?.url.includes('youtube-nocookie.com/embed/')) {
        return null // Exit early if not a YouTube NoCookie URL.
      }
      return (
        <BlockYouTubeNoCookie
          {...{url: attributes?.url, providerNameSlug: 'youtube'}}
          key={index}
        />
      )
    }

    default:
      return <pre key={index}>{JSON.stringify(block, null, 2)}</pre>
  }
}

displayBlock.propTypes = {
  block: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired
}
